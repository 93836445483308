import { LayoutBreakpoint } from '@/styles/layout';
import { useEffect, useState } from 'react';

const useBreakpoint = (): [LayoutBreakpoint | undefined, LayoutBreakpoint[]] => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<LayoutBreakpoint>();
  const [activeBreakpoints, setActiveBreakpoints] = useState<LayoutBreakpoint[]>([]);

  useEffect(() => {
    const handleResize = () => {
      const style = getComputedStyle(document.body);
      const activeBreakpointVar = style.getPropertyValue('--breakpoint-all-active') ?? '';
      const currentBreakpointVar = style.getPropertyValue('--breakpoint-current') ?? '';

      setCurrentBreakpoint(currentBreakpointVar.trim() as LayoutBreakpoint);
      setActiveBreakpoints(activeBreakpointVar.split(',').map((b) => b.trim()) as LayoutBreakpoint[]);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [currentBreakpoint, activeBreakpoints];
};

export default useBreakpoint;
