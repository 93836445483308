import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { paddingX } from '@/styles/layout/utils';
import { FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${COLORS.GRAY.HIGHLIGHT};
  border-radius: ${remCalc(10)};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${TEXT_SIZES[13]}
  font-weight: ${FONT_WEIGHTS.BOLD};
  text-transform: uppercase;
  letter-spacing: 0.08em;

  ${paddingX(30)};
  height: ${remCalc(60)};
`;

const ToggleButton = styled.button`
  margin: 0 0 0 auto;
  width: ${remCalc(32)};
  height: ${remCalc(32)};
  border-radius: 100%;
  background-color: ${hexToRGBA(COLORS.GRAY.BASE, 0.25)};
  ${customTextSize(18, 0)};
  user-select: none;
`;

const Content = styled.div`
  padding: ${remCalc(8, 30, 30, 30)};
`;

interface ExpandingBlockProps extends PropsWithClassName, PropsWithChildren {
  title: string;
  open?: boolean;
  forceOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
}

const ExpandingBlock: FC<ExpandingBlockProps> = (props) => {
  const [isOpen, setIsOpen] = useState(props.forceOpen);

  useEffect(() => {
    setIsOpen(props.forceOpen || props.open || false);
  }, [props.open]);

  const onToggle = () => {
    if (props.forceOpen) return;

    const newState = !isOpen;

    setIsOpen(newState);

    if (props.onToggle) props.onToggle(newState);
  };

  return (
    <Wrapper className={props.className}>
      <Header
        {...(!props.forceOpen && {
          role: 'button',
          onClick: onToggle,
        })}
      >
        <span>{props.title}</span>

        {!props.forceOpen && <ToggleButton>{isOpen ? '-' : '+'}</ToggleButton>}
      </Header>

      {(props.forceOpen || isOpen) && <Content>{props.children}</Content>}
    </Wrapper>
  );
};

ExpandingBlock.defaultProps = {
  forceOpen: false,
};

export default ExpandingBlock;

export { Header as ExpandingBlockHeader };
