import Footer from '@/components/_rebrand/Footer';
import Header from '@/components/_rebrand/Header';
import GroupedInput from '@/components/forms/GroupedInput';
import { Container } from '@/components/layout/Container';
import BlogNavigation from '@/components/navigation/BlogNavigation';
import SubscribeOverlay from '@/components/overlays/SubscribeOverlay';
import FOOTER_PROPS from '@/constants/footer';
import HEADER_PROPS from '@/constants/header';
import { HubspotFieldNames } from '@/constants/hubspot';
import { GlobalContext } from '@/context';
import { UserContext } from '@/context/user';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { mediaBreakpointUp, paddingY } from '@/styles/layout/utils';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import MasterTemplate from '@/templates/MasterTemplate';
import { FC, PropsWithChildren, useContext } from 'react';
import styled from 'styled-components';

// #region - Components

const SubscribeFooter = styled.section`
  ${paddingY(120)}

  ${mediaBreakpointUp('desktop')} {
    ${paddingY(180)}
  }
`;

const SubscribeFooterHeadline = styled.p`
  ${customTextSize(38, 42)};
  max-width: ${remCalc(700)};
  margin: 0 auto ${remCalc(32)};

  ${mediaBreakpointUp('tablet')} {
    ${customTextSize(52, 64)};
    text-align: center;
    margin-bottom: ${remCalc(48)};
  }
`;

const SubscribeFooterGroupedInput = styled(GroupedInput)`
  ${mediaBreakpointUp('tablet')} {
    margin: 0 auto;
    max-width: ${remCalc(500)};
  }
`;

// #endregion - Components

const BlogTemplate: FC<PropsWithChildren> = ({ children }) => {
  const { blog: blogGlobalVariables, showSubscribeModal, toggleSubscribeModal } = useContext(GlobalContext);
  const { userData, setUserData } = useContext(UserContext);

  const [currentBreakpoint] = useBreakpoint();

  return (
    <MasterTemplate
      navigation={
        <>
          <Header {...HEADER_PROPS} forceOpaque />
          <BlogNavigation />
        </>
      }
      footer={
        <>
          <SubscribeFooter>
            <Container>
              <SubscribeFooterHeadline>{blogGlobalVariables?.subscribeText}</SubscribeFooterHeadline>
              <SubscribeFooterGroupedInput
                name="email"
                type="email"
                value={userData[HubspotFieldNames.Email]}
                placeholder="Your Email Address"
                submitText="Subscribe"
                split={currentBreakpoint === 'mobile'}
                onValueChange={(email) => setUserData({ [HubspotFieldNames.Email]: email })}
                onSubmit={() => toggleSubscribeModal(true)}
              />
            </Container>
          </SubscribeFooter>
          <Footer {...FOOTER_PROPS} />
        </>
      }
      globalIncludes={
        showSubscribeModal ? (
          <SubscribeOverlay
            email={userData[HubspotFieldNames.Email]}
            onClose={() => {
              toggleSubscribeModal(false);
            }}
          />
        ) : null
      }
    >
      {children}
    </MasterTemplate>
  );
};

export default BlogTemplate;
