import Link from '@/components/Link';
import { COLORS } from '@/styles/color';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { truncate } from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: ${remCalc(10)};
  background-color: ${COLORS.WHITE};
  box-shadow: 0px 0px 10px 0px rgba(0, 27, 56, 0.1);
`;

const CardImageContainer = styled.div`
  flex-shrink: 0;
  border-radius: ${remCalc(10, 10, 0, 0)};
  overflow: hidden;
  aspect-ratio: 370 / 220;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${remCalc(30)};
  height: 100%;
`;

const CardImage = styled.div<{ objectPosition: string }>`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    object-position: ${(props) => props.objectPosition};
  }
`;

const CategoryText = styled.p`
  ${customTextSize(12, 20)};
  font-weight: ${FONT_WEIGHTS.BOLD};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: ${remCalc(10)};
`;

const TitleText = styled.h2`
  ${customTextSize(24, 30)};
  font-weight: ${FONT_WEIGHTS.BOLD};
  letter-spacing: 0.01em;
  margin-bottom: ${remCalc(30)};
`;

const Summary = styled.p`
  ${customTextSize(16, 24)};
  margin-bottom: ${remCalc(30)};
  margin-top: ${remCalc(-20)};
`;

const ReadMoreLink = styled(Link)`
  ${customTextSize(16, 20)};
  display: inline-block;
  align-self: flex-start;
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  margin-top: auto;
`;

interface BlogArticleCardProps extends PropsWithClassName {
  title: string;
  category?: string;
  summary?: string;
  href: string;
  image?: React.ReactNode;
  imageObjectPosition?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const BlogArticleCard: FC<BlogArticleCardProps> = (props) => {
  return (
    <Card className={props.className}>
      {props.image && (
        <CardImageContainer>
          <Link href={props.href}>
            <a title={props.title} onClick={props.onClick}>
              <CardImage objectPosition={props.imageObjectPosition!}>{props.image}</CardImage>
            </a>
          </Link>
        </CardImageContainer>
      )}

      <CardContent>
        {props.category && <CategoryText>{props.category}</CategoryText>}

        <Link href={props.href} title={props.title} onClick={props.onClick}>
          <TitleText>{props.title}</TitleText>
        </Link>

        {props.summary && <Summary>{truncate(props.summary, { length: 200 })}</Summary>}

        <ReadMoreLink href={props.href} title={props.title} onClick={props.onClick}>
          Read more →
        </ReadMoreLink>
      </CardContent>
    </Card>
  );
};

BlogArticleCard.defaultProps = {
  imageObjectPosition: 'center',
};

export default BlogArticleCard;

export {
  CategoryText as BlogArticleCardCategoryText, CardContent as BlogArticleCardContent,
  CardImage as BlogArticleCardImage, CardImageContainer as BlogArticleCardImageContainer, ReadMoreLink as BlogArticleCardReadMoreLink, Summary as BlogArticleCardSummary, TitleText as BlogArticleCardTitleText
};
