import PrimaryButton from '@/components/buttons/primary';
import InputField, { InputFieldIconWrapper, InputFieldProps } from '@/components/forms/InputField';
import InputFieldElement from '@/components/forms/InputFieldElement';
import { COLORS } from '@/styles/color';
import { remCalc } from '@/styles/typography/utils';
import { FC, FormEventHandler } from 'react';
import styled, { css } from 'styled-components';

const StyledInputField = styled(InputField)`
  ${InputFieldElement} {
    --input-border-radius: ${remCalc(999)};
    --input-border-color: var(--grouped-input-border-color, ${COLORS.BLUE.LOWLIGHT});
    --input-background-color: var(--grouped-input-background-color, ${COLORS.WHITE});
    --input-padding-y: ${remCalc(16)};
    --input-padding-x: ${remCalc(16)};

    height: 100%;
    text-align: center;
  }

  ${InputFieldIconWrapper} {
    right: ${remCalc(18)};
  }

  width: 100%;
  text-align: center;
`;

const SubmitButton = styled(PrimaryButton)`
  --button-color: var(--grouped-input-border-color, ${COLORS.BLUE.LOWLIGHT});
  --button-color-hover: var(--grouped-input-button-hover-color, var(--grouped-input-border-color, ${COLORS.BLUE.BASE}));

  /* Weird hack */
  & {
    width: 100%;
  }
`;

interface FormElementProps {
  split: boolean;
}

const Form = styled.div<FormElementProps>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: minmax(1fr, auto) 1fr;
  row-gap: ${remCalc(16)};

  ${(props) =>
    !props.split &&
    css`
      display: flex;
      flex-direction: row;

      ${InputFieldElement} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
        text-align: left;
        padding-left: ${remCalc(24)};
      }

      ${StyledInputField} {
        margin-bottom: 0;
        text-align: left;
      }

      ${SubmitButton} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: auto;
        flex-shrink: 0;
      }
    `}
`;

interface GroupedInputProps extends InputFieldProps {
  submitText: string;
  split: boolean;
  onSubmit?: () => void;
  onButtonClick?: () => void;
}

const GroupedInput: FC<GroupedInputProps> = ({ submitText, split, onSubmit, onButtonClick, className, ...props }) => {
  const onFormSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (onSubmit) onSubmit();
  };

  return (
    <Form className={className} split={split}>
      <StyledInputField onEnterKey={onFormSubmit} {...props} />
      <SubmitButton
        onClick={(e) => {
          onFormSubmit(e);
          if (onButtonClick) onButtonClick();
        }}
      >
        {submitText}
      </SubmitButton>
    </Form>
  );
};

export default GroupedInput;
