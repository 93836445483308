import { ReactComponent as CloseIcon } from '@/assets/images/svg/glyphs/close.svg';
import { HubspotFieldNames, HubspotFieldValidators, HubspotFormIDs } from '@/constants/hubspot';
import { useCustomFormik, useFormikHubspotSubmit } from '@/lib/formik';
import gtm from '@/lib/gtm';
import useBreakpoint from '@/lib/hooks/useBreakpoint';
import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { LockBodyScroll } from '@/styles/layout/body';
import { mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_FAMILIES, FONT_WEIGHTS, TEXT_SIZES } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrimaryDefaultButton } from '../buttons/primary';
import GroupedInput from '../forms/GroupedInput';
import { InputFieldMessage } from '../forms/InputField';
import { Container } from '../layout/Container';

// #region - Components

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${hexToRGBA(COLORS.BLACK, 0.95)};
  z-index: 1000;
`;

const OverlayScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  padding: ${remCalc(18)};

  ${Container} {
    width: 100%;
  }
`;

const OverlayCloseButton = styled.button`
  position: absolute;
  top: ${remCalc(32)};
  right: ${remCalc(32)};
  appearance: none;
  border: none;
  background: none;
  box-sizing: content-box;
  width: ${remCalc(16)};
  height: ${remCalc(16)};
  padding: ${remCalc(16)};
  margin: 0;
  cursor: pointer;

  svg {
    path {
      fill: ${COLORS.WHITE};
    }
  }
`;

const Headline = styled.h2`
  ${customTextSize(24, 30)}
  font-family: ${FONT_FAMILIES.SERIF};
  color: ${COLORS.WHITE};
  text-align: center;
  font-weight: ${FONT_WEIGHTS.BOLD};

  ${mediaBreakpointUp('tablet')} {
    ${customTextSize(40, 48)}
  }
`;

const StyledGroupedInput = styled(GroupedInput)`
  margin-top: ${remCalc(32)};
  max-width: ${remCalc(600)};
  margin: ${remCalc(40)} auto 0;

  --grouped-input-border-color: ${COLORS.WHITE};
  --input-border-color-hover: ${COLORS.WHITE};
  --button-color: ${COLORS.RED.BASE};
  --button-text-color: ${COLORS.WHITE};
  --button-color-hover: ${COLORS.RED.LOWLIGHT};

  ${mediaBreakpointUp('tablet')} {
    ${InputFieldMessage} {
      padding-left: ${remCalc(26)};
    }
  }
`;

const FormError = styled.p`
  ${TEXT_SIZES[14]};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  color: ${COLORS.RED.LOWLIGHT};
  text-align: center;
  margin: ${remCalc(20)} auto 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${remCalc(40)};
`;

const StyledPrimaryDefaultButton = styled(PrimaryDefaultButton)`
  --button-color: ${COLORS.WHITE};
  --button-text-color: ${COLORS.RED.BASE};
  --button-color-hover: ${COLORS.RED.LOWLIGHT};
`;

// #endregion - Components

interface SubscribeOverlayProps {
  email?: string;
  onClose: () => void;
}

const SubscribeOverlay: FC<SubscribeOverlayProps> = (props) => {
  const [currentBreakpoint] = useBreakpoint();
  const router = useRouter();

  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  const formik = useCustomFormik({
    initialValues: {
      [HubspotFieldNames.Email]: props.email,
    },
    onSubmit: useFormikHubspotSubmit({
      formId: HubspotFormIDs.BlogSubscribe,
      name: 'Front Page Subscribe Overlay Form',
      content: 'Front Page Subscribe Overlay Form',
      beforeSubmit: () => {
        setFormError('');
      },
      onSuccess: (values, actions) => {
        setSubscribed(true);

        gtm.trackBlogSubscribe();
      },
      onError: (errors, values, actions) => {
        setFormError('An unexpected error occured, please try again.');
      },
    }),
    validate: (values) => {
      const errors = {};

      errors[HubspotFieldNames.Email] = HubspotFieldValidators[HubspotFieldNames.Email](
        values[HubspotFieldNames.Email] ?? '',
        {
          required: true,
          allowPersonalEmail: true,
          allowSharedEmail: true,
        },
      );

      if (Object.values(errors).every((val) => val === undefined)) return;

      return errors;
    },
  });

  /* Submit on open when email provided */
  useEffect(() => {
    if (props.email) formik.submitForm();

    gtm.event(`front_page_subscribe_overlay_opened`);

    gtm.track('modal_opened', {
      location: 'Front Page',
      element_label: 'Subscribe Overlay',
    });

    router.push(
      {
        pathname: router.pathname,
        query: router.query,
        hash: '#subscribe',
      },
      undefined,
      {
        shallow: true,
      },
    );

    return () => {
      gtm.event(`front_page_subscribe_overlay_closed`);

      gtm.track('modal_closed', {
        location: 'Front Page',
        element_label: 'Subscribe Overlay',
      });

      router.push(
        {
          pathname: router.pathname,
          query: router.query,
          hash: '',
        },
        undefined,
        {
          shallow: true,
        },
      );
    };
  }, []);

  return (
    <>
      <Overlay>
        <OverlayScrollWrapper>
          <OverlayCloseButton onClick={props.onClose}>
            <CloseIcon />
          </OverlayCloseButton>

          {subscribed ? (
            <Container>
              <Headline>Thank you for subscribing!</Headline>

              <ButtonWrapper>
                <StyledPrimaryDefaultButton onClick={props.onClose} fullWidth={currentBreakpoint === 'mobile'}>
                  Back to Front Page
                </StyledPrimaryDefaultButton>
              </ButtonWrapper>
            </Container>
          ) : (
            <Container>
              <Headline>Subscribe to get the latest from Front Page</Headline>

              <form onSubmit={formik.handleSubmit} noValidate>
                <StyledGroupedInput
                  name={HubspotFieldNames.Email}
                  type="email"
                  value={formik.values[HubspotFieldNames.Email]}
                  placeholder="you@company.com"
                  submitText={formik.isSubmitting ? 'Submitting...' : 'Subscribe'}
                  split={currentBreakpoint === 'mobile'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onSubmit={formik.handleSubmit}
                  errorMessage={formik.errors[HubspotFieldNames.Email] as string}
                  invalid={formik.touched[HubspotFieldNames.Email] && !!formik.errors[HubspotFieldNames.Email]}
                  disabled={formik.isSubmitting}
                />
              </form>

              {!!formError && <FormError>{formError}</FormError>}
            </Container>
          )}
        </OverlayScrollWrapper>
      </Overlay>
      <LockBodyScroll />
    </>
  );
};

export default SubscribeOverlay;
